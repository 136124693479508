<section class="dashboard-section p-40">
    <div class="row referral-page">
      <div class="col-lg-6">
        <div class="info-card">
          <div class="card-title">
            <h6 class="card-title-text">{{'earn10PercentOfEveryGmpdDeposit' | translate}}</h6>
            <p class="card-title-description">{{filteredReferrals.length}} {{filteredReferrals.length === 1 ? ('userStakedGmpdSoFar' | translate) : ('usersStakedGmpdSoFar' | translate)}}</p>
          </div>
  
          <iframe
            [src]="referralProgramVideoUrl"
            frameborder="0"
            allowfullscreen
            class="video"
          ></iframe>
  
          <p class="card-context" [innerHTML]="'referralDescription' | translate"></p>
        </div>
      </div>
  
      <div class="col-lg-6">
        <div class="referral-control-card">
          <div class="referral-control-card-header">
            <div class="card-title">
              <h6 class="card-title-text">{{'shareYourLinkWithOthers' | translate}}</h6>
            </div>
  
            <button class="address-btn --copy w-100" matTooltip="{{'referralLink' | translate}}" [cdkCopyToClipboard]="refLink">
              <span class="ref-link">{{refLink}}</span>
            </button>
  
            <div class="social-links-wrapper">
              <p class="social-links-wrapper-text">{{'share' | translate}}</p>
              <div class="social-links-list">
                <a class="social-link" href="https://twitter.com/share?url={{refLink}}" target="_blank">
                  <img src="assets/images/referral/social-icons/twitter.svg" alt="twitter">
                </a>
                <a class="social-link" href="https://www.linkedin.com/sharing/share-offsite/?url={{refLink}}" target="_blank">
                  <img src="assets/images/referral/social-icons/linkedin.svg" alt="linkedin">
                </a>
                <a class="social-link" href="https://www.facebook.com/sharer.php?u={{refLink}}" target="_blank">
                  <img src="assets/images/referral/social-icons/facebook.svg" alt="facebook">
                </a>
                <a class="social-link" href="https://telegram.me/share/url?url={{refLink}}" target="_blank">
                  <img src="assets/images/referral/social-icons/telegram.svg" alt="telegram">
                </a>
                <a class="social-link" href="https://reddit.com/submit?url={{refLink}}" target="_blank">
                  <img src="assets/images/referral/social-icons/reddit.svg" alt="reddit">
                </a>
                <a class="social-link" href="https://wa.me/?text={{refLink}}" target="_blank">
                  <img src="assets/images/referral/social-icons/whatsapp.svg" alt="whatsapp">
                </a>
                <a class="social-link" href="https://pinterest.com/pin/create/button/?media={{refLink}}" target="_blank">
                  <img src="assets/images/referral/social-icons/pinterest.svg" alt="pinterest">
                </a>
                <a class="social-link" href="mailto:?subject=Referral link&body={{refLink}}" target="_blank">
                  <img src="assets/images/referral/social-icons/email.svg" alt="email">
                </a>
                <a class="social-link" href="https://vk.com/share.php?url={{refLink}}" target="_blank">
                  <img src="assets/images/referral/social-icons/vk.svg" alt="vk">
                </a>
  
                <button [matMenuTriggerFor]="socialNetworks" class="social-link">
                  <i class="icomoon-more"></i>
                </button>
  
                <mat-menu #socialNetworks="matMenu" class="select-social-network-menu" backdropClass="select-social-network-menu-backdrop" xPosition="before">
                  <div (click)="$event.stopPropagation()" class="menu-header">
                    <p class="menu-header-text">{{'share' | translate}}</p>
                    <i class="icomoon-close" (click)="socialNetworks.close.emit()"></i>
                  </div>
  
                  <div (click)="$event.stopPropagation()" class="social-links-list">
                    <a class="social-link" href="https://connect.ok.ru/offer?url={{refLink}}" target="_blank">
                      <img src="assets/images/referral/social-icons/ok.svg" alt="ok">
                    </a>
                    <a class="social-link" href="https://www.livejournal.com/update.bml?subject={{refLink}}" target="_blank">
                      <img src="assets/images/referral/social-icons/livejournal.svg" alt="livejournal">
                    </a>
                    <a class="social-link" href="https://social-plugins.line.me/lineit/share?url={{refLink}}" target="_blank">
                      <img src="assets/images/referral/social-icons/line.svg" alt="line">
                    </a>
                    <a class="social-link" href="https://web.skype.com/share?url={{refLink}}" target="_blank">
                      <img src="assets/images/referral/social-icons/skype.svg" alt="skype">
                    </a>
                    <a class="social-link" href="https://story.kakao.com/share?url={{refLink}}" target="_blank">
                      <img src="assets/images/referral/social-icons/kakao.svg" alt="kakao">
                    </a>
                    <a class="social-link" href="https://www.tumblr.com/widgets/share/tool?canonicalUrl=https://app.bullperks.com/signup?referrer=0xaceaecb820be3f147ff40d1c221b25f10ee8dd92&posttype=link" target="_blank">
                      <img src="assets/images/referral/social-icons/tumblr.svg" alt="tumblr">
                    </a>
                  </div>
                </mat-menu>
              </div>
            </div>
          </div>
  
          <div class="referral-control-card-item">
            <p class="title">{{'youEarned10Percents' | translate}}</p>
            <p class="value">{{totalRewardsAmount | shiftDecimals:-18| number: '1.0-2'}} GMPD</p>
          </div>
  
          <div class="referral-control-card-item">
            <p class="title">{{'claimable' | translate}}</p>
            <p class="value">{{userRefBonusBalance$| async | shiftDecimals:-18| number: '1.0-2'}} GMPD</p>
          </div>
  
          <div class="referral-control-card-item">
              <button mat-raised-button color="primary" [ngClass]="{'inactive': refBonusBalance < 1500}" class="w-100 claim" (click)="claimReward()">
                {{'claimReward' | translate}}
              </button>
          </div>
  
        </div>
      </div>
  
      <div *ngIf="referrals.length" class="col-lg-12">
        <div class="referrals-list-card">
          <div class="referrals-list-title">
            <div class="col-4 px-2">
              {{'address' | translate}}
            </div>
            <div class="col-4 px-2">
              {{'gmpdStaked' | translate}}
            </div>
            <div class="col-4 px-2">
              {{'yourShare10Percents' | translate}}
            </div>
          </div>
          <div class="referral-list-item" *ngFor="let ref of referrals">
            <div class="col-4 px-2 referral-list-item-text">
              {{ref.address}}
            </div>
            <div class="col-4 px-2 referral-list-item-text">
              <img class="gmpd-icon" src="assets/images/logo-gmpd.png" alt="">
              {{ref.stackedAmount | shiftDecimals:-18| number: '1.0-2'}}
            </div>
            <div class="col-4 px-2 referral-list-item-text" [ngClass]="{'zero-value': ref.rewardAmount == '0', 'your-share': ref.rewardAmount != '0'}">
              {{ref.rewardAmount | shiftDecimals:-18| number: '1.0-2'}} GMPD
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  