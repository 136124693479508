import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { NoopScrollStrategy } from "@angular/cdk/overlay";
import { ComponentBase } from "../../shared/component-base";
import { EventBus } from "../../shared/event-bus";
import { Web3Service } from "../../shared/web3-service";
import BigNumber from "bignumber.js";
import { DlgContractService } from "../dlg-contract.service";

import { AlertService } from "../shared-dlg.module";
import { UserSessionProvider } from "../../shared/user-session-provider";
import { ActivatedRoute, Router } from "@angular/router";
import {
  AddressBookEntryDTO,
  AddressBookServiceProxy,
  DealDTO,
  DealRegistrationDTO,
  DealServiceProxy,
  UserDTO,
  UsersServiceProxy,
} from "../../service-proxies/service-proxies";
import { NetworkNamePipe } from "../../shared/pipes/networkName.pipe";
import { DlgAddressBookComponent } from "../dlg-address-book";
import { environment } from "src/environments/environment";
import { DlgDisclaimerComponent } from "../dlg-disclaimer/dlg-disclaimer.component";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "deal-detail",
  templateUrl: "./deal-detail.component.html",
  styleUrls: ["./deal-detail.component.scss"],
})
export class DealDetailComponent extends ComponentBase implements OnInit, OnDestroy {
  constructor(
    private _dialog: MatDialog,
    private _dlgContractSrv: DlgContractService,
    private _alertSrv: AlertService,
    private eventBus: EventBus,
    private web3Service: Web3Service,
    private userSessionProvider: UserSessionProvider,
    private route: ActivatedRoute,
    private router: Router,
    private dealService: DealServiceProxy,
    private usersService: UsersServiceProxy,
    private addressBookServiceProxy: AddressBookServiceProxy,
    public translate: TranslateService,
  ) {
    super();

    this.now = Math.floor(Date.now() / 1000);

    this.updateTimeTimerId = setInterval(() => {
      this.now = Math.floor(Date.now() / 1000);

      let diffStart = 0;
      if (this.isUpcoming) {
        diffStart = this.startTimestamp - this.now;
      } else if (this.isStarted) {
        diffStart = this.finishTimestamp - this.now;
      }
      if (diffStart > 0) {
        this.timerViewDays = Math.floor(diffStart / (3600 * 24));
        this.timerViewHours = Math.floor((diffStart % (3600 * 24)) / 3600);
        this.timerViewMin = Math.floor((diffStart % 3600) / 60);
        this.timerViewSec = Math.floor(diffStart % 60);
      } else {
        this.timerViewDays = 0;
        this.timerViewHours = 0;
        this.timerViewMin = 0;
        this.timerViewSec = 0;
      }
    }, 1000);
  }

  public readonly defaultClaimingUrl: string = environment.defaultClaimingUrl;

  userInWhitelist: boolean = false;
  isCollectWalletDeal: boolean = false;
  emissionAddress: string;

  deal: DealDTO;
  disclaimerAgreed: boolean = false;
  userInfo: UserDTO;
  dealRegistration: DealRegistrationDTO;
  addressBookEntry: AddressBookEntryDTO;

  kycConfirmed: boolean = false;
  isAuthorized: boolean = false;

  waiting: boolean = false;
  account: string = "";
  dealAddress: string = "";
  balance: string = '0';

  //#region contract data
  public isApprovedPaymentToken: boolean = false;
  paymentToken: string;

  tokenPrice: string;
  rewardToken: string;
  rewardDecimals: number;
  dealStartTimestamp: number;
  startTimestamp: number;
  finishTimestamp: number;
  startClaimTimestamp: number;
  maxDistributedTokenAmount: string;
  totalRaise: string; // Sum of all payments (in payment token)
  tokensForDistribution: string;
  leftTokensForDistribution: string;
  minimumRaise: string;
  distributedTokens: string = "0";
  allowRefund: boolean;

  vestingContractAddress: string;
  vestingPercent: number;
  vestingStart: number;
  vestingInterval: number;
  vestingDuration: number;

  //#end region contract data
  paymentDecimal: number;
  paymentTokenSymbol: string;
  rewardTokenSymbol: string;
  tokensPerETH: number;
  targetNetwork: number;

  userTier: any;
  userTierIndex: number = -1;
  userTierIsNft: boolean;
  userBonusTierIndex: number = -1;
  bonusInterval: number = 0;
  isBonusActive: boolean = false;
  allTiers: any[] = new Array();
  baseTicketSize: any;

  updateTimeTimerId: NodeJS.Timeout;
  updateUserTimerId: NodeJS.Timeout;
  updateDealTimerId: NodeJS.Timeout;

  now: number;

  timerViewDays: number;
  timerViewHours: number;
  timerViewMin: number;
  timerViewSec: number;

  //Last participation time
  lastParticipationTime: number = 0;
  lockupDuration: number = 0;

  claimURL: string;

  lockerUserBalance: string;
  stakingUserBalance: string;
  farmingUserBalance: string;
  bonusBalance: string;
  totalUserLockedBalance: string;

  public get amountInputClass(): string {
    return this.userTierIsNft || this.userCustomRaise > 0 ? "ticket-increased ticket-increased-input" : "";
  }

  public get needToWaitTime(): number {
    let needTiWait = this.lastParticipationTime + this.lockupDuration - this.now;
    if (needTiWait > 0) return needTiWait;
    else return 0;
  }

  usersTicketSize = 0;
  userCustomRaise = 0;

  public usersTicketSizeInRewardTokens = 0;

  public increaseTicketSizeWithNft(ticketSize: string): string {
    var tiketSizeBn = new BigNumber(ticketSize);

    if (this.userTierIsNft) {
      tiketSizeBn = tiketSizeBn.plus(tiketSizeBn.dividedBy(2));
    }

    return tiketSizeBn.toFixed();
  }

  public increaseTicketSizeWithBonus(ticketSize: string): string {
    var tiketSizeBn = new BigNumber(ticketSize);

    if (this.userCustomRaise > 0) {
      const multiplier = new BigNumber(this.userCustomRaise + 100);
      tiketSizeBn = tiketSizeBn.multipliedBy(multiplier).dividedBy(100);
    }

    return tiketSizeBn.toFixed();
  }

  private notNegative(amount: number): number {
    return amount >= 0 ? amount : 0;
  }

  public async recalcTicketSizeChange(): Promise<void> {
    this.handleTicketSizeChange({ value: this.usersTicketSize * this.tokensPerETH });
    await this.updateUserData();
  }

  public async handleTicketSizeChange(target: any): Promise<void> {
    let value = +target.value;
    if (this.deal.dealType === 2 && /[\-0-9,.]*/.test(target.value)) {
      value = Math.round(value);
    }
    if (value < 0) {
      value = 0;
    }
    if (this.deal.dealType === 2 && value < 1) {
      value = 1;
    }

    if (value > this.userTierMaxTokens - this.usersDebtAmount) {
      value = this.userTierMaxTokens - this.usersDebtAmount;
    }

    this.usersTicketSize = this.notNegative(Number((value / this.tokensPerETH).toFixed(4)));
    this.usersTicketSizeInRewardTokens = this.notNegative(Number(value.toFixed(4)));
    await this.updateUserData();
  }

  public handleKeyDown(event: any) {
    return this.deal.dealType !== 2 || event.charCode >= 48 && event.charCode <= 57;
  }

  get ticketSizeToNumber(): number {
    if (this.userTier?.ticketSize) {
      return new BigNumber(this.userTier.ticketSize).shiftedBy(-this.paymentDecimal).toNumber();
    }
    return 0;
  }

  async setMaxUsersTicketSize(num?: number, num2?: number): Promise<void> {
    const max: any = this.userTierMaxTokens;
    this.usersTicketSize = this.notNegative(Number((this.ticketSizeToNumber - num).toFixed(4)));
    this.usersTicketSizeInRewardTokens = this.notNegative(Number((max - num2).toFixed(4)));
    await this.updateUserData();
  }


  get userTierMaxTokens() {
    if (this.userTier?.ticketSize) {
      return new BigNumber(this.userTier.ticketSize)
        .shiftedBy(this.paymentDecimal)
        .dividedBy(this.tokenPrice)
        .shiftedBy(-this.paymentDecimal)
        .toNumber();
    }
    return 0;
  }

  usersDebtAmount: number;
  usersTotalPaymentAmount: number;
  usersClaimedTokensAmount: number;
  usersReleasableAmount: number = 0;
  usersVestedAmount: number = 0;
  usersVestedReleasedAmount: number = 0;

  public get payTooltip(): string {
    if (this.isBonusActive) {
      return this.translate.instant('payDelayedAfterDealStart', { minutes: Math.floor(this.bonusInterval / 60) })
    }
    else {
      return this.translate.instant('theTimeWhenWillBeAbleMakePayment')
    }
  }

  public get allowClaim(): boolean {
    if (!this.startClaimTimestamp || this.startClaimTimestamp == 0 || this.deal?.hideTgeDate) {
      return false;
    }
    return this.now > this.startClaimTimestamp;
  }

  public get isUpcoming(): boolean {
    return this.now < this.startTimestamp;
  }

  public get isStarted(): boolean {
    return this.now > this.startTimestamp && this.now < this.finishTimestamp;
  }

  public get isFinished(): boolean {
    return this.now > this.finishTimestamp;
  }

  public get allowPay(): boolean {
    if (!this.startTimestamp) {
      return false;
    }
    if (!this.finishTimestamp) {
      return false;
    }

    if (this.userInWhitelist) {
      return this.now < this.finishTimestamp;
    } else {
      return this.now > this.startTimestamp && this.now < this.finishTimestamp;
    }
  }

  public get canPayMore(): boolean {
    return new BigNumber(this.totalRaise).lt(new BigNumber(this.maxDistributedTokenAmount)) &&
           new BigNumber(this.usersTotalPaymentAmount).toNumber() < new BigNumber(this.userTier?.ticketSize).shiftedBy(-18).toNumber();

  }

  public get getDistributedPercent(): number {
    if (this.maxDistributedTokenAmount && this.tokensForDistribution) {
      return new BigNumber(this.tokensForDistribution)
        .div(this.maxDistributedTokenAmount)
        .multipliedBy(100)
        .toNumber();
    }
    return 0;
  }

  public getDistributedPercentTier(maxDistributedTokenAmountTier: number, tokensForDistributionTier: number): number {
    if (maxDistributedTokenAmountTier && tokensForDistributionTier) {
      return new BigNumber(tokensForDistributionTier)
        .div(maxDistributedTokenAmountTier)
        .multipliedBy(100)
        .toNumber();
    }
    return 0;
  }

  public get getDistributedLeft(): string {
    if (this.maxDistributedTokenAmount && this.tokensForDistribution) {
      return new BigNumber(this.maxDistributedTokenAmount).minus(this.tokensForDistribution).toString();
    } else {
      return "0";
    }
  }

  public getTotalRaiseETH(deal: DealDTO): number {
    return deal ? new BigNumber(deal.maxDistributedTokenAmount)
      .shiftedBy(-deal.rewardDecimal)
      .multipliedBy(deal.tokenPrice)
      .shiftedBy(-deal.paymentDecimal)
      .toNumber() : 0;
  }

  async ngOnInit() {
    this.isAuthorized = this.userSessionProvider.isAuthorized;

    this.route.queryParams.subscribe(params => {
      this.dealAddress = params["address"];
      console.log(`deal address: ${this.dealAddress}`);
      this.dealService.getByAddress(this.dealAddress).subscribe(
        result => {
          this.deal = result;
          this.rewardTokenSymbol = this.deal.rewardTokenSymbol;
          this.isCollectWalletDeal = this.deal.isDealWithoutDistribution;
          this.paymentTokenSymbol = this.deal.paymentTokenSymbol;
          this.targetNetwork = this.deal.targetNetwork;
          this.claimURL = this.deal.claimURL;

          console.log("account=" + this.account);
          console.log("targetNetwork=" + this.targetNetwork);
          this.addressBookServiceProxy.getByUserWalletAndChainId(this.userSessionProvider.linkedWallet, this.targetNetwork).subscribe(
            result => {
              this.addressBookEntry = result;
              this.emissionAddress = this.addressBookEntry?.emissionAddress;
            },
            error => {
              console.error(error);
            },
          );
          console.log(this.deal);
          this.checkWhiteList();
          if (this.web3Service.chainIdNumber != this.deal.chainId) {
            const chainName = new NetworkNamePipe().transform(this.deal.chainId);
            this.showErrorModal(this.translate.instant("selectNetworkInMetamask", { chainName }));
            this.router.navigate(["/deals"]);
          }
        },
        error => {
          console.error(error);
        },
      );
    });

    if (this.userSessionProvider.isAuthorized) {
      this.usersService.getMe().subscribe(
        result => {
          this.userInfo = result;
          this.kycConfirmed = result.kycConfirmed;
        },
        error => {
          this.processServiceError(error);
        },
      );

      this.dealService.getRegistration(this.dealAddress).subscribe(
        result => {
          this.dealRegistration = result;
        },
        error => {
          this.processServiceError(error);
        },
      );
    }

    this.tokenPrice = await this.web3Service.getDealTokenPrice(this.dealAddress);
    this.paymentDecimal = 18;
    this.tokensPerETH = new BigNumber(1).shiftedBy(this.paymentDecimal).dividedBy(this.tokenPrice).toNumber();
    this.finishTimestamp = parseInt(await this.web3Service.getDealFinishTimestamp(this.dealAddress));

    if (this.userSessionProvider.linkedWallet) {
      await this.eventLogin(this.userSessionProvider.linkedWallet);
      if (this.deal.paymentToken != "0x0000000000000000000000000000000000000000") {
        this.web3Service.GetTokenBalance(this.account, this.deal.paymentToken).then((value) => {
          this.balance = this.toNumberFromWeiFixed(value, 18).toString();
        });
      } else {
        this.web3Service.getEthBalance(this.account).then((value) => {
          this.balance = this.toNumberFromWeiFixed(value, 18).toString();
        });
      }
    }

    this.lockerUserBalance = await this.web3Service.getLockedTokenAmount(
      this.web3Service.lockerAddress,
      this.account,
    );

    this.stakingUserBalance = (await this.getStackingBalance()).reduce(
      (partialSum: string, a: string) => BigInt(partialSum) + BigInt(a),
      0,
    );

    this.farmingUserBalance = (
      await this.web3Service.getPoolUserInfo(this.account, this.web3Service.getFarmingAddress)
    )[0];

    const totalGMPD = BigInt(this.lockerUserBalance) + BigInt(this.stakingUserBalance) + BigInt(this.farmingUserBalance);

    this.totalUserLockedBalance = String(totalGMPD.toString().replace('n', ''));

    this.bonusBalance = await this.web3Service.getBonusAmount(this.web3Service.bonusAddress, this.account);

    await this.web3Service.initWeb3();
    if (this.web3Service.web3) {
      await this.updateDealContractData();
      await this.updateUserData();

      await this.updateDealDataInterval();
      this.updateDealTimerId = setInterval(() => {
        this.updateDealDataInterval();
      }, this.expectedBlockTime);
    }

    this.eventBus.loginEvent.subscribe(result => {
      console.log("loginEvent subscription:" + result);
      this.eventLogin(result);
    });

    this.eventBus.logoutEvent.subscribe(result => {
      console.log("logoutEvent subscription:" + result);
      this.eventLogout();
    });
  }

  private async getPoolBalance(poolAddress: string): Promise<string> {
    const poolBalanceData = await this.web3Service.getPoolUserInfo(this.account, poolAddress);
    return poolBalanceData?.[0] || 0;
  }

  private async getStackingBalance(): Promise<any[]> {
    return await Promise.all(
      this.web3Service.getStackingAddresses.map(async poolAddress => await this.getPoolBalance(poolAddress)),
    );
  }

  async checkWhiteList(): Promise<void> {
    if (this.deal.enabledWhitelisting && this.userSessionProvider.isAuthorized) {
      // Check if user in white list
      await this.dealService.checkWhiteList(this.dealAddress).toPromise().then(result => {
        this.userInWhitelist = result.value;
      }, error => console.error(error));
      // .subscribe(
      //     result => {
      //         this.userInWhitelist = result.value;
      //     },
      //     error => {
      //         console.error(error);
      //     },
      // );
    }
  }

  async eventLogin(username: string) {
    console.log("eventLogin");
    console.log(username);
    if (this.account != username) {
      this.account = username;

      //TODO:HACK call early than updateDealContractData
      await this.setRewardDecimals();
      this.paymentToken = await this.web3Service.getDealPaymentToken(this.dealAddress);
      if (this.paymentToken != "0x0000000000000000000000000000000000000000") {
        this.paymentDecimal = parseInt(await this.web3Service.GetDecimals(this.paymentToken));
      } else {
        this.paymentDecimal = 18;
        this.isApprovedPaymentToken = true;
      }

      await this.updateUserData();

      this.updateUserTimerId = setInterval(() => {
        this.updateUserData();
      }, this.expectedBlockTime);

      //this.getTokensDebts(username);
    }
  }

  eventLogout(): void {
    console.log("signOut");
    this.account = "";

    //this.tokenDebt = 0;
    //this.totalInvestedETH = 0;
    //this.ethBalance = 0;
    //this.totalStakeETH = null;
    //this.totalBuyToken = null;
    //this.myClaimedTokensAmount = null;
    //this.tokensDebt = new Array();

    if (this.updateUserTimerId) {
      clearInterval(this.updateUserTimerId);
    }
  }

  async payClick() {
    if (!this.dealRegistration) {
      this.showWarningModal(this.translate.instant("clickJoinDeal"));
      return;
    }
    if (this.needToWaitTime > 0) {
      this.showWarningModal(this.translate.instant("youNeedWaitLockupPeriod"));
    }

    this.disclaimerAgreed = this.disclaimerAgreed || (localStorage.getItem("disclaimerAgreed" + this.deal.dealAddress) == this.userInfo.ethAddress);
    if (this.deal.showDisclaimer && !this.disclaimerAgreed) {
      const dialogRef = this._dialog.open(DlgDisclaimerComponent, {
        panelClass: ["dlg-light"],
        scrollStrategy: new NoopScrollStrategy(),
      });
      dialogRef.disableClose = true;
      dialogRef.componentInstance.disclaimerText = this.deal.disclaimerText;
      dialogRef.componentInstance.disclaimer = this.deal.disclaimer;
      dialogRef.afterClosed().subscribe(result => {
        console.log("DlgDisclaimerComponent result:", result);
        this.disclaimerAgreed = result;
        if (this.disclaimerAgreed) {
          localStorage.setItem("disclaimerAgreed" + this.deal.dealAddress, this.userInfo.ethAddress);
          this.payClickWithWalletAddress();
        }
      });
      return;
    }
    this.waiting = true;

    //const dialogRef = this._dlgContractSrv.showWaitingConfirmation();
    let ticketSizeString = "0x" + new BigNumber(this.usersTicketSize).shiftedBy(this.paymentDecimal).toString(16);
    const contractEventsSource = this.web3Service.dealPay(
      this.dealAddress,
      this.account,
      ticketSizeString,
      this.dealRegistration.signature,
      this.deal.paymentToken == "0x0000000000000000000000000000000000000000",
    );

    contractEventsSource.transactionHash$.subscribe(val => {
      //this.waiting = false;
      this._dialog.closeAll();
      console.log(`transactionHash$ ${val}`);
      //this._dlgContractSrv.showSubmitted({ tx: val });
      this._alertSrv.show(this.translate.instant("transactionSubmitted"));
    });

    try {
      await contractEventsSource.receipt$.toPromise();

      //dialogRef.close();
      this._alertSrv.show(this.translate.instant("confirmedTransaction"));
      this.updateUserData();
    } catch (err) {
      //dialogRef.close();
      console.info("catch");
      console.info(err);
    } finally {
      await this.updateDealContractData();
      this.waiting = false;
    }
  }

  async payClickWithWalletAddress() {
    if (!this.dealRegistration) {
      this.showWarningModal(this.translate.instant("clickJoinDeal"));
      return;
    }
    if (!this.emissionAddress) {
      this.showWarningModal(this.translate.instant("needToSetEmissionAddress"));
      return;
    }
    if (this.needToWaitTime > 0) {
      this.showWarningModal(this.translate.instant("youNeedWaitLockupPeriod"));
    }
    this.disclaimerAgreed = this.disclaimerAgreed || (localStorage.getItem("disclaimerAgreed" + this.deal.dealAddress) == this.userInfo.ethAddress);
    if (this.deal.showDisclaimer && !this.disclaimerAgreed) {
      const dialogRef = this._dialog.open(DlgDisclaimerComponent, {
        panelClass: ["dlg-light"],
        scrollStrategy: new NoopScrollStrategy(),
      });
      dialogRef.disableClose = true;
      dialogRef.componentInstance.disclaimerText = this.deal.disclaimerText;
      dialogRef.componentInstance.disclaimer = this.deal.disclaimer;
      dialogRef.afterClosed().subscribe(result => {
        console.log("DlgDisclaimerComponent result:", result);
        this.disclaimerAgreed = result;
        if (this.disclaimerAgreed) {
          localStorage.setItem("disclaimerAgreed" + this.deal.dealAddress, this.userInfo.ethAddress);
          this.payClickWithWalletAddress();
        }
      });
      return;
    }
    this.waiting = true;

    //const dialogRef = this._dlgContractSrv.showWaitingConfirmation();
    let ticketSizeString = "0x" + new BigNumber(this.usersTicketSize).shiftedBy(this.paymentDecimal).toString(16);
    const contractEventsSource = this.web3Service.dealPayWithEmissionAddress(
      this.dealAddress,
      this.account,
      this.emissionAddress,
      ticketSizeString,
      this.dealRegistration.signature,
      this.deal.paymentToken == "0x0000000000000000000000000000000000000000",
    );

    contractEventsSource.transactionHash$.subscribe(val => {
      this._dialog.closeAll();
      console.log(`transactionHash$ ${val}`);
      //this._dlgContractSrv.showSubmitted({ tx: val });
      this._alertSrv.show(this.translate.instant("transactionSubmitted"));
    });

    try {
      await contractEventsSource.receipt$.toPromise();

      //dialogRef.close();
      this._alertSrv.show(this.translate.instant("confirmedTransaction"));
      this.updateUserData();
    } catch (err) {
      //dialogRef.close();
      console.info("catch");
      console.info(err);
    } finally {
      await this.updateDealContractData();
      this.waiting = false;
    }
  }

  async claimClick() {
    this.waiting = true;

    //const dialogRef = this._dlgContractSrv.showWaitingConfirmation();

    const contractEventsSource = this.web3Service.dealClaim(this.dealAddress, this.account);

    contractEventsSource.transactionHash$.subscribe(val => {
      this.waiting = false;
      this._dialog.closeAll();
      console.log(`transactionHash$ ${val}`);
      //this._dlgContractSrv.showSubmitted({ tx: val });
      this._alertSrv.show(this.translate.instant("transactionSubmitted"));
    });

    try {
      await contractEventsSource.receipt$.toPromise();

      //dialogRef.close();
      this._alertSrv.show(this.translate.instant("confirmedTransaction"));
      this.updateUserData();
    } catch (err) {
      //dialogRef.close();
      console.info("catch");
      console.info(err);
    }
    this.waiting = false;
  }

  async claimVesingClick() {
    this.waiting = true;

    //const dialogRef = this._dlgContractSrv.showWaitingConfirmation();

    const contractEventsSource = this.web3Service.vestingRelease(this.vestingContractAddress, this.account);

    contractEventsSource.transactionHash$.subscribe(val => {
      this.waiting = false;
      this._dialog.closeAll();
      console.log(`transactionHash$ ${val}`);
      //this._dlgContractSrv.showSubmitted({ tx: val });
      this._alertSrv.show(this.translate.instant("transactionSubmitted"));
    });

    try {
      await contractEventsSource.receipt$.toPromise();

      //dialogRef.close();
      this._alertSrv.show(this.translate.instant("confirmedTransaction"));
      this.updateUserData();
    } catch (err) {
      //dialogRef.close();
      console.info("catch");
      console.info(err);
    }
    this.waiting = false;
  }

  async updateDealContractData() {
    console.log("Get contract data");
    this.paymentToken = await this.web3Service.getDealPaymentToken(this.dealAddress);
    if (this.paymentToken != "0x0000000000000000000000000000000000000000") {
      this.web3Service.GetContractSymbol(this.paymentToken).then(resp => {
        this.paymentTokenSymbol = resp;
      });
      this.paymentDecimal = parseInt(await this.web3Service.GetDecimals(this.paymentToken));
    } else {
      //this.paymentTokenSymbol = get from DTO
      this.paymentDecimal = 18;
      this.isApprovedPaymentToken = true;
    }
    this.tokenPrice = await this.web3Service.getDealTokenPrice(this.dealAddress);
    this.rewardToken = await this.web3Service.getDealRewardToken(this.dealAddress);

    //TODO: if another chain reward token will be empty
    if (this.rewardToken) {
      this.web3Service.GetContractSymbol(this.rewardToken).then(resp => {
        this.rewardTokenSymbol = resp;
      });
    }

    await this.setRewardDecimals();

    this.tokensPerETH = new BigNumber(1).shiftedBy(this.paymentDecimal).dividedBy(this.tokenPrice).toNumber();

    this.dealStartTimestamp = parseInt(await this.web3Service.getDealStartTimestamp(this.dealAddress));
    this.startTimestamp = this.dealStartTimestamp;
    this.finishTimestamp = parseInt(await this.web3Service.getDealFinishTimestamp(this.dealAddress));
    this.startClaimTimestamp = parseInt(await this.web3Service.getDealStartClaimTimestamp(this.dealAddress));

    this.web3Service.getDealMinimumRaise(this.dealAddress).then(resp => {
      this.minimumRaise = resp;
    });

    this.web3Service.getDealAllowRefund(this.dealAddress).then(resp => {
      this.allowRefund = Boolean(resp);
    });

    const tierLength = parseInt(await this.web3Service.getDealTiersLength(this.dealAddress));
    this.allTiers = [];
    for (let i = 0; i < tierLength; i++) {
      let tier = await this.web3Service.getDealTiers(this.dealAddress, i) || await this.web3Service.getOldDealTiers(this.dealAddress, i);
      console.log(tier);
      if (this.userTierIsNft) {
        tier.ticketSize = this.increaseTicketSizeWithNft(tier.ticketSize);
      }
      this.allTiers.push(tier);
    }

    this.maxDistributedTokenAmount = this.deal.maxDistributedTokenAmount;

    this.web3Service.getVestingPercent(this.dealAddress).then(resp => {
      this.vestingPercent = parseInt(resp);
    });

    let vestAddress = await this.web3Service.getDealVestingAddress(this.dealAddress);
    this.vestingContractAddress = vestAddress == "0x0000000000000000000000000000000000000000" ? null : vestAddress;

    if (this.vestingContractAddress) {
      this.web3Service.getVVestingStart(this.vestingContractAddress).then(resp => {
        this.vestingStart = parseInt(resp);
      });

      this.web3Service.getVVestingInterval(this.vestingContractAddress).then(resp => {
        this.vestingInterval = parseInt(resp);
      });

      this.web3Service.getVVestingDuration(this.vestingContractAddress).then(resp => {
        this.vestingDuration = parseInt(resp);
      });
    }
  }

  updateAllowancePaymentToken(ticketSize: number): void {
    if (this.paymentToken != "0x0000000000000000000000000000000000000000") {
      //Проверяем разрешение тратить pool token в размере tokenSupply
      this.web3Service.GetAllowance(this.account, this.paymentToken, this.dealAddress).then(resp => {
        console.log("GetAllowance paymentToken", new BigNumber(resp).shiftedBy(-this.paymentDecimal));
        if (new BigNumber(resp).shiftedBy(-this.paymentDecimal).gte(new BigNumber(ticketSize))) {
          this.isApprovedPaymentToken = true;
        } else {
          this.isApprovedPaymentToken = false;
        }
      });
    }
  }

  async approvePaymentClick(): Promise<void> {
    this.waiting = true;

    let usersTicketSizeBN = new BigNumber(this.usersTicketSize);
    let amountBN = usersTicketSizeBN.shiftedBy(this.paymentDecimal);

    this.web3Service.approveForDeal(
      this.account,
      this.paymentToken,
      this.dealAddress,
      amountBN.toFixed(),
    ).then(() => {
      this._alertSrv.show(this.translate.instant("confirmedTransaction"));
      this.updateAllowancePaymentToken(this.usersTicketSize);
    }).catch((err: any) => {
      console.info("catch");
      console.info(err);
    }).finally(() => {
      this.waiting = false;
    });

    //contractEventsSource.transactionHash$
    //  .pipe(tap(() => this._dlgContractSrv.showSubmitted()))
    //  .subscribe();

    this.waiting = false;
  }

  async updateDealDataInterval() {
    this.web3Service.getDealTokensForDistribution(this.dealAddress).then(resp => {
      this.tokensForDistribution = resp;
    });

    this.web3Service.getDealTotalRaise(this.dealAddress).then(resp => {
      this.totalRaise = resp;
    });

    this.web3Service.getDealDistributedTokens(this.dealAddress).then(resp => {
      this.distributedTokens = resp;
    });
  }

  async setRewardDecimals() {
    //Check if deal without distribution
    let rewardTokenAddress = await this.web3Service.getDealRewardToken(this.dealAddress);
    if (!rewardTokenAddress) {
      this.rewardDecimals = 18;
    } else {
      this.rewardDecimals = parseInt(await this.web3Service.getDealDecimals(this.dealAddress));
    }
  }

  async updateUserData() {
    await this.setRewardDecimals();

    this.web3Service.getDealUserInfo(this.dealAddress, this.account).then(userInfo => {
      this.usersDebtAmount = this.toNumberFromWei(userInfo.debt, this.rewardDecimals);
      this.usersTotalPaymentAmount = this.toNumberFromWei(userInfo.totalPayment, this.paymentDecimal);
      this.usersClaimedTokensAmount = this.toNumberFromWei(
        new BigNumber(userInfo.total).minus(userInfo.debt).toString(),
        this.rewardDecimals,
      );
    });

    this.web3Service.getLastParticipations(this.account).then(resp => {
      this.lastParticipationTime = parseInt(resp);
    });

    let vestAddress = await this.web3Service.getDealVestingAddress(this.dealAddress);
    this.vestingContractAddress = vestAddress == "0x0000000000000000000000000000000000000000" ? null : vestAddress;

    if (this.vestingContractAddress) {
      this.web3Service.getVestingReleasableAmount(this.vestingContractAddress, this.account).then(resp => {
        this.usersReleasableAmount = this.toNumberFromWei(resp, this.rewardDecimals);
      });

      this.web3Service.getVestingForUser(this.vestingContractAddress, this.account).then(resp => {
        this.usersVestedAmount = this.toNumberFromWei(resp[0], this.rewardDecimals);
        this.usersVestedReleasedAmount = this.toNumberFromWei(resp[1], this.rewardDecimals);
      });
    }

    var bonusTierInfo = await this.web3Service.getDealUsersBonusTierIndex(this.dealAddress, this.account);
    if (Boolean(bonusTierInfo[0])) {
      this.userBonusTierIndex = parseInt(bonusTierInfo[1]);
    }

    this.web3Service.getDealUsersTierIndex(this.dealAddress, this.account).then(async resp => {
      //TODO: check response success
      if (this.deal.enabledWhitelisting && this.userInWhitelist && resp[0] == false) {
        resp = [1, 0, 0];
      }
      if (Boolean(resp[0]) || this.userInWhitelist || this.userBonusTierIndex != -1) {
        if (Boolean(resp[0])) {
          this.userTierIndex = parseInt(resp[1]);
        }

        if ((this.userBonusTierIndex != -1 || this.userTierIndex != -1) && this.userBonusTierIndex >= this.userTierIndex && this.bonusBalance > this.totalUserLockedBalance /*this.userBonusTierIndex > this.userTierIndex*/) {
          this.isBonusActive = true;
          this.userTierIndex = this.userBonusTierIndex;
          this.bonusInterval = parseInt(await this.web3Service.getBonusInterval());
          this.startTimestamp = this.dealStartTimestamp + this.bonusInterval;
        }
        else {
          this.isBonusActive = false;
        }

        this.userTierIsNft = resp[2];
        this.userTier = await this.web3Service.getDealTiers(this.dealAddress, this.userTierIndex);

        try {
          this.userCustomRaise = Number(await this.web3Service.getDealUserCustomRaise(this.dealAddress, this.account));
        }
        catch {
          this.userCustomRaise = 0;
        }

        if (!this.baseTicketSize) {
          this.baseTicketSize = this.userTierIsNft ? this.increaseTicketSizeWithNft(this.userTier.ticketSize) : this.userTier.ticketSize;
        }

        this.userTier.ticketSize = this.baseTicketSize;

        if (this.userCustomRaise > 0) {
          this.userTier.ticketSize = this.increaseTicketSizeWithBonus(this.baseTicketSize);
        }

        if (this.usersTicketSize == 0) {
          this.usersTicketSize = this.notNegative(Number((this.toNumberFromWei(this.userTier.ticketSize, this.paymentDecimal) - this.usersTotalPaymentAmount).toFixed(4)));
          this.usersTicketSizeInRewardTokens = this.notNegative(Number((this.tokensPerETH * parseFloat((this.toNumberFromWei(this.userTier.ticketSize, this.paymentDecimal) - this.usersTotalPaymentAmount).toFixed(4))).toFixed(4)));
        }
        console.log("userTier");
        console.log(this.userTier);
        this.updateAllowancePaymentToken(this.usersTicketSize);

        this.web3Service.getDealLockupsTiers(this.userTierIndex).then(async resp => {
          this.lockupDuration = parseInt(resp[2]);
        });
      } else {
        console.error(resp);
        console.error(`Tier not found for user: ${this.account}`);
      }
    });
  }

  joinDealClick() {
    if (this.userSessionProvider.isAuthorized) {
      if (this.kycConfirmed) {
        this.dealService.registrate(this.dealAddress).subscribe(
          result => {
            this.dealRegistration = result;
            this.showSuccessModal(this.translate.instant("youAreJoined"));
          },
          error => {
            this.processServiceError(error);
          },
        );
      } else {
        this.navigateToKYC();
      }
    } else {
      this.navigateToLogin();
    }
  }

  processServiceError(error: any) {
    if (error.status == 401) {
      console.error("401");
      this.userSessionProvider.finishAuth();
      this.navigateToLogin();
    } else this._alertSrv.show(JSON.parse(error.response).message, "error");
  }

  navigateToLogin(): void {
    this.router.navigate(["/login"]);
  }

  navigateToKYC(): void {
    this.router.navigate(["/kyc"]);
  }

  addToGoogleCalendar() {
    const startDate = new Date(this.deal.startTime * 1000).toISOString().replace(/-|:|\.\d\d\d/g, "");
    const finishDate = new Date(this.deal.finishTime * 1000).toISOString().replace(/-|:|\.\d\d\d/g, "");
    const url = new URL("https://www.google.com/calendar/render");
    const eventInfo =
      "Deal details: <a href=\"https://app.gamespad.io/deal-detail?address=" +
      this.deal.dealAddress +
      "\">" +
      "https://app.gamespad.io/deal-detail?address=" +
      this.deal.dealAddress +
      "</a>";
    url.searchParams.append("action", "TEMPLATE");
    url.searchParams.append("text", this.deal.name + " Deal on Gamespad");
    url.searchParams.append("dates", startDate + "/" + finishDate);
    url.searchParams.append("details", eventInfo);
    url.searchParams.append("sf", "true");
    url.searchParams.append("output", "xml");

    window.open(url.toString(), "_blank").focus();
  }

  async ngOnDestroy() {
    if (this.updateUserTimerId) {
      clearInterval(this.updateUserTimerId);
    }
    if (this.updateDealTimerId) {
      clearInterval(this.updateDealTimerId);
    }
    if (this.updateTimeTimerId) {
      clearInterval(this.updateTimeTimerId);
    }
  }

  scrollToDescription() {
    document.getElementById("deal-about").scrollIntoView();
  }

  public openAddAddressDlg() {
    const dialogRef = this._dialog.open(DlgAddressBookComponent, {
      panelClass: ["dlg-light", "dlg-medium"],
      scrollStrategy: new NoopScrollStrategy(),
    });
    dialogRef.disableClose = true;
    dialogRef.componentInstance.actionAddressMode = "add";
    let dto = new AddressBookEntryDTO();
    dto.networkId = this.deal.targetNetwork;
    dto.userWallet = this.account;
    dialogRef.componentInstance.dto = dto;
  }

  public get hideClaimButton(): boolean {
    const dealsAddresses = ["0xc31d2b7a038b6f17a9e620da5b2c5d62091b9e1d"];
    return dealsAddresses.includes(this.dealAddress);
  }

  getTranslatedDealTypeName(value: number): string {
    if (value == 0)
      return this.translate.instant("vc");
    else if (value == 1)
      return this.translate.instant("ido");
    else if (value == 2)
      return this.translate.instant("ino");
    else if (value == 3)
      return this.translate.instant("equity");
    return value.toString();
  }

  getTranslatedTier(tierIndex: number) {
    if (tierIndex == -1) return this.translate.instant("none");
    if (tierIndex == 0) return this.translate.instant("bot");
    if (tierIndex == 1) return this.translate.instant("cyborg");
    if (tierIndex == 2) return this.translate.instant("android");
    if (tierIndex == 3) return this.translate.instant("humanoid");
    if (tierIndex == 4) return this.translate.instant("cryptoNinja");
    return "None";
  }

  getTranslatedShowPeriod(value: number): string {
    const timerViewDays = Math.floor(value / (3600 * 24));
    const timerViewHours = Math.floor(value % (3600 * 24) / 3600);
    const timerViewMin = Math.floor(value % 3600 / 60);
    const timerViewSec = Math.floor(value % 60);
    let stringData = "";
    if (timerViewDays)
      stringData += `${timerViewDays} ${this.translate.instant("time.day")} `;
    if (timerViewHours)
      stringData += `${timerViewHours} ${this.translate.instant("time.hours")} `;
    if (timerViewMin)
      stringData += `${timerViewMin} ${this.translate.instant("time.min")} `;
    if (timerViewSec)
      stringData += `${timerViewSec} ${this.translate.instant("time.ss")} `;
    return stringData;
  }

  hasSocialMedia(): string {
    return (
      this.deal?.twitterURL ||
      this.deal?.mediumURL ||
      this.deal?.youTubeURL ||
      this.deal?.facebookURL ||
      this.deal?.telegramURL ||
      this.deal?.linkedInURL ||
      this.deal?.instagramURL ||
      this.deal?.telegramChannelURL
    );
  }

  get firstTokenCustomPrice(): string{
    if(this.deal.customPrice && this.deal.investmentPercentage){
      return this.deal.customPrice.toString();
    }
    return this.deal.tokenPrice;
  }

  get secondTokenCustomPrice(): string{
    if(this.deal.secondTokenCustomPrice && this.deal.secondTokenInvestmentPercentage){
      return this.deal.secondTokenCustomPrice.toString();
    }
    return this.deal.tokenPrice;
  }

  get firstTokenDistributionQuantity(){
    if(this.deal.customPrice && this.deal.investmentPercentage){
    return (Number(this.totalRaise) * Number(this.deal.investmentPercentage) / Number(100) / Number(this.deal.customPrice)).toFixed(2).replace(/[.,]00$/, "");
    }
    return (Number(this.totalRaise) / 10 ** this.paymentDecimal).toString();
  }

  get secondTokenDistributionQuantity(){
    if(this.deal.secondTokenCustomPrice && this.deal.secondTokenInvestmentPercentage){
      return (Number(this.totalRaise) * Number(this.deal.secondTokenInvestmentPercentage) / Number(100) / Number(this.deal.secondTokenCustomPrice)).toFixed(2).replace(/[.,]00$/, "");
    }
    return (Number(this.totalRaise) / 10 ** this.paymentDecimal).toString();
  }

  get firstTokenMaxDistributedTokenAmount(){
    if(this.deal.customPrice && this.deal.investmentPercentage){
      return (Number(this.maxDistributedTokenAmount) * Number(this.deal.investmentPercentage) / Number(100) / Number(this.deal.customPrice) * Number(this.deal.tokenPrice)).toFixed(2).replace(/[.,]00$/, "");
    }
    return this.maxDistributedTokenAmount;
  }

  get secondTokenMaxDistributedTokenAmount(){
    if(this.deal.secondTokenCustomPrice && this.deal.secondTokenInvestmentPercentage){
      return (Number(this.maxDistributedTokenAmount) * Number(this.deal.secondTokenInvestmentPercentage) / Number(100) / Number(this.deal.secondTokenCustomPrice) * Number(this.deal.tokenPrice)).toFixed(2).replace(/[.,]00$/, "");
    }
    return this.maxDistributedTokenAmount;
  }

  get firstTokenUserTotalDebtAmount(){
    if(this.deal.customPrice && this.deal.investmentPercentage){
      return (Number(this.usersDebtAmount) * Number(this.deal.investmentPercentage) / Number(100) / Number(this.deal.customPrice) * Number(this.deal.tokenPrice)).toFixed(2).replace(/[.,]00$/, "");;
    }
    return this.usersDebtAmount;
  }

  get secondTokenUserTotalDebtAmount(){
    if(this.deal.secondTokenCustomPrice && this.deal.secondTokenInvestmentPercentage){
      return (Number(this.usersDebtAmount) * Number(this.deal.secondTokenInvestmentPercentage) / Number(100) / Number(this.deal.secondTokenCustomPrice) * Number(this.deal.tokenPrice)).toFixed(2).replace(/[.,]00$/, "");
    }
    return this.usersDebtAmount;
  }

  getPurchasedFirstTokens(purchaseQuantity: string){
    if(this.deal.customPrice && this.deal.investmentPercentage){
      let purchaseQuantityDigit = Number(purchaseQuantity);
      return (purchaseQuantityDigit * Number(this.deal.investmentPercentage) / Number(100) / Number(this.deal.customPrice) * Number(this.deal.tokenPrice)).toFixed(2).replace(/[.,]00$/, "");
    }
    return purchaseQuantity;
  }

  getPurchasedSecondTokens(purchaseQuantity: string){
    if(this.deal.secondTokenCustomPrice && this.deal.secondTokenInvestmentPercentage){
      let purchaseQuantityDigit = Number(purchaseQuantity);
      return (purchaseQuantityDigit * Number(this.deal.secondTokenInvestmentPercentage) / Number(100) / Number(this.deal.secondTokenCustomPrice) * Number(this.deal.tokenPrice)).toFixed(2).replace(/[.,]00$/, "");
    }
    return purchaseQuantity;
  }

  get firstTokenIconUrl(){
    if(this.deal?.tokenIconUrl?.length !=  0){
      return this.deal.tokenIconUrl
    }
    return this.deal?.logoURL ? this.deal.logoURL :'../../assets/images/no-image.svg';
  }

  get secondTokenIconUrl(){
    if(this.deal?.secondTokenIconUrl?.length !=  0){
      return this.deal.secondTokenIconUrl;
    }
    return this.deal?.logoURL ? this.deal.logoURL :'../../assets/images/no-image.svg';
  }
}
